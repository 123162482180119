import './bootstrap';
import { createRoot } from 'react-dom/client';
import React, { useEffect, useState } from 'react';
import logo from '../assets/svgs/logo.svg';
import { Main } from './pages/main/main';
import {
  createBrowserRouter,
  RouterProvider,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import { Day } from '@/pages/day/day.tsx';
import {
  useInitData,
  WebAppProvider,
} from '@vkruglikov/react-telegram-web-app';
import { Theme } from '@/pages/theme/theme.tsx';
import { Section } from '@/pages/section';
import { clsx } from 'clsx';
import { Menu } from '@/ui/menu/menu.tsx';
import { Search } from '@/pages/search/search.tsx';
import { ReviewPage } from '@/pages/review';
import { Help } from '@/pages/help';
import { GameComponent } from '@/game/game-component.tsx';
import { RatingTable } from '@/game/rating.tsx';

interface LayoutProps {
  children: React.ReactNode;
  isGame?: boolean;
  isMain?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, isMain, isGame }) => {
  const [user, setUser] = useState<number | undefined>(undefined);
  const [_, initData] = useInitData();
  const navigate = useNavigate();
  const [course, setCourse] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`/api/auth`, {
      method: 'POST',
      body: initData,
    })
      .then((response) => response.json())
      .then((data) => {
        return data.success ? setUser(data.user_id) : setUser(undefined);
      });
  }, []);

  useEffect(() => {
    console.log(
      `layout: user: ${user}, initData: ${initData}, userAgent:${navigator.userAgent} `,
    );
  }, [user]);

  const isSection = useMatch('/day/:dayId/themes/:themeId/sections/:sectionId');

  if (isGame) {
    return (
      <div className="flex flex-col bg-green min-h-screen font-sans relative">
        {children}
        <Menu isGame />
      </div>
    );
  }

  return (
    user && (
      <div
        className={clsx(
          isSection || isGame ? 'relative' : 'px-4',
          'mx-auto flex flex-col bg-gradient min-h-screen pt-4 font-sans',
        )}
      >
        <div
          className={clsx(isSection || isGame ? 'px-4 absolute top-4' : '', '')}
          onClick={() => navigate('/')}
        >
          {/*<img src={logo as unknown as string} className="h-6" alt="logo" />*/}
        </div>
        {children}
        <Menu isMain={isMain} />
      </div>
    )
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Layout isMain={true}>
        <Main />
      </Layout>
    ),
  },
  {
    path: '/game',
    element: (
      <Layout isGame={true}>
        <GameComponent />
      </Layout>
    ),
  },
  {
    path: '/game/rating',
    element: (
      <Layout isGame={true}>
        <RatingTable />
      </Layout>
    ),
  },
  {
    path: '/day/:dayId',
    element: (
      <Layout>
        <Day />
      </Layout>
    ),
  },
  {
    path: '/day/:dayId/review',
    element: (
      <Layout>
        <ReviewPage />
      </Layout>
    ),
  },
  {
    path: '/day/:dayId/themes/:themeId',
    element: (
      <Layout>
        <Theme />
      </Layout>
    ),
  },
  {
    path: '/day/:dayId/themes/:themeId/sections/:sectionId',
    element: (
      <Layout>
        <Section />
      </Layout>
    ),
  },
  {
    path: '/search',
    element: (
      <Layout>
        <Search />
      </Layout>
    ),
  },
  {
    path: '/help',
    element: (
      <Layout>
        <Help />
      </Layout>
    ),
  },
]);

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <WebAppProvider>
      <RouterProvider router={router} />
    </WebAppProvider>
  </React.StrictMode>,
);
