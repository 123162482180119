import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowRight } from '../../../assets/icons/arrow-right.tsx';
import { CheckCircle } from '../../../assets/icons/check-circle.tsx';
import { useInitData } from '@vkruglikov/react-telegram-web-app';
import { clsx } from 'clsx';
import { BreadCrumbItem, BreadCrumbs } from '@/ui/breadCrumbs';
import reviewIcon from '../../../assets/svgs/review.svg';
import { Loader } from '@/ui/loader';

interface Theme {
  id: number;
  sections_count: number;
  completed_count: number;
  title: string;
}

const Themes: React.FC<{ themes: Theme[]; dayId: string }> = ({
  themes,
  dayId,
}) => {
  const navigate = useNavigate();

  return (
    <ul className="flex w-full overflow-y-scroll scrollbar-hide flex-col p-4 bg-white gap-y-1 shadow-1 rounded-xl mt-8">
      {themes.map((theme, index) => (
        <li
          key={theme.id}
          onClick={() => navigate(`/day/${dayId}/themes/${theme.id}`)}
          className={clsx(
            theme.completed_count === theme.sections_count
              ? 'bg-soft-green-20'
              : 'bg-soft-gray-20',
            'flex flex-col relative p-4 list-none justify-start rounded-[16px]',
          )}
        >
          <div className="flex flex-row w-full items-center justify-start gap-x-2">
            <CheckCircle
              className="stroke-purple h-3.5"
              isChecked={theme.completed_count === theme.sections_count}
            />
            <span
              className={clsx(
                index < 1
                  ? 'text-purple'
                  : 'text-light-basic-content-2 opacity-40',
                'text-[10px] font-bold leading-[18px]',
              )}
            >
              Выполнено {theme.completed_count} / {theme.sections_count}
            </span>
          </div>
          <div className="mt-1 flex flex-row items-center justify-between w-full">
            <span className="font-medium text-xs leading-4">
              {++index}. {theme.title}
            </span>
            <ArrowRight className="h-4" size={16} />
          </div>
        </li>
      ))}
    </ul>
  );
};

const Day: React.FC = () => {
  const { dayId } = useParams();

  const navigate = useNavigate();
  const [initDataUnsafe, initData] = useInitData();

  const [course, setCourse] = useState<string>();
  const [day, setDay] = useState<number>();
  const [themes, setThemes] = useState<Theme[]>([]);
  const [isViewReview, setIsViewReview] = useState<boolean>(true);
  const [rated, setRated] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    themes.map((theme) => {
      setIsViewReview((prew) => {
        const res = theme.completed_count === theme.sections_count;
        return !prew ? false : res;
      });
    });
  }, [themes]);

  useEffect(() => {
    console.log('rated', rated);
  }, []);

  useEffect(() => {
    fetch(`/api/days/${dayId}/themes`)
      .then((response) => response.json())
      .then((data) => {
        console.log('data', data.rated);
        setRated(data.rated);
        setCourse(data.course);
        setDay(data.day);
        setThemes(data.themes);
      })
      .catch(() => {
        setRated(false);
        setCourse('');
        setDay(0);
        setThemes([]);
      })
      .then(() => setIsLoading(false));
  }, [dayId]);

  useEffect(() => {
    console.log('initDataUnsafe', initDataUnsafe.user?.id);
  }, [initData, initDataUnsafe]);

  const breadCrumbsItems: BreadCrumbItem[] = [
    {
      text: course!,
      isActive: false,
      onClick: () => navigate('/'),
    },
    {
      text: `День #${day}`,
      isActive: true,
      onClick: () => navigate(`/day/${dayId}`),
    },
  ];

  const SendReview = () => {
    const navigate = useNavigate();
    return (
      <button
        onClick={() => navigate(`/day/${dayId}/review`)}
        className="mt-4 w-full py-3 px-4 bg-white rounded-[12px] flex flex-row items-center justify-between"
      >
        <div className="flex flex-row items-center gap-x-2.5">
          <img src={reviewIcon} alt="иконка отзыва" />
          <span className="text-xs font-medium leading-4">Оставить отзыв</span>
        </div>
        <ArrowRight />
      </button>
    );
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="overflow-y-scroll pb-[80px] scrollbar-hide h-screen">
      <h1 className="mt-[18px] text-white text-3xl font-bold leading-9">
        {day}-й день
      </h1>
      <span className="text-white leading-6 font-medium opacity-70 text-base mt-1.5">
        Ознакомьтесь с темами дня
      </span>

      {day && <BreadCrumbs items={breadCrumbsItems} />}
      <Themes themes={themes} dayId={dayId!} />
      {isViewReview && !rated && <SendReview />}
    </div>
  );
};

export { Day };
